import { atom } from 'recoil';
import OnBoardingPagesConst from '../constants/OnBoardingPagesConst.js';

export const vehicleStateAtom = atom({
    key: 'vehicleState',
    default: {}
});

export const vehicleStatusAtom = atom({
    key: 'vehicleStatus',
    default: '--'
});

export const vehicleStateOfChargeAtom = atom({
    key: 'vehicleStateOfCharge',
    dafault: 50
});

export const chargeDoneTimeAtom = atom({
    key: 'chargeDoneTime',
    default: ''
});

export const chargeSettingsAtom = atom({
    key: 'chargeSettings',
    default: {
        panelTitle: 'Charge Settings',
        target: 0,
        targetLabel: 'target'
    }
});

export const departureTimeAtom = atom({
    key: 'departureTime',
    default: { time: 'Not Set' }
});

export const energyScheduleAtom = atom({
    key: 'energySchedule',
    default: { state: 'Empty', chargeWindows: [] }
});

export const scheduleStateAtom = atom({
    key: 'scheduleState',
    default: 'Empty'
});

export const powerOutageAtom = atom({
    key: 'powerOutage',
    default: null
});

export const fetchLoadingAtom = atom({
    key: 'fetchLoading',
    default: true
});

export const onboardingPreviousPageAtom = atom({
    key: 'onboardingPreviousPage',
    default: OnBoardingPagesConst.UTILITY_PLAN
});

export const onboardingPageCurrentlyShownAtom = atom({
    key: 'onboardingPageToNavigateTo',
    default: OnBoardingPagesConst.UTILITY_PLAN
});

export const onboardingScheduleAtom = atom({
    key: 'onboardingSchedule',
    default: {
        utilityId: '',
        planId: '',
        weekdays: {
            dischargeList: []
        },
        weekends: {
            dischargeList: []
        }
    }
});

export const showSideMenuAtom = atom({
    key: 'showSideMenu',
    default: false
});
