import React, { useEffect, useState } from 'react';
import './UtilityPlanSelector.scss';
import { Button, Dropdown, Stack, Tab, Tabs } from 'react-bootstrap';
import LoadingScreen from '../LoadingScreen/LoadingScreen.jsx';
import OnBoardingPagesConst from '../../constants/OnBoardingPagesConst.js';
import ERROR_STATUS from '../../constants/ErrorStatusConst.js';
import {
    onboardingPageCurrentlyShownAtom,
    onboardingPreviousPageAtom,
    onboardingScheduleAtom
} from '../../atoms/vehicleState.js';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../../env/environment.js';
import { AMPLITUDE_EVENTS, trackEvent } from '../../util/Amplitude.js';
import { customerStateAtom } from '../../atoms/customerState.js';
import { useBackButtonHandler } from '../OnBoardingContainer/UseBackButtonHandler.jsx';
import BackButton from '../../assets/icons/Back_Arrow_Left_icon.svg';
import { MAILTO_LINK } from '../../constants/HelpLinks.js';
import PCTNotSet from '../PctNotSetErrorPage/PCTNotSet.jsx';

export default function UtilityPlanSelector({ isEditFlow, exitOnboarding }) {
    const [loading, setLoading] = useState(true);
    const [isUtilityPlanDropdownOpen, setIsUtilityPlanDropdownOpen] = useState(false);
    const [utilityPlanHeight, setUtilityPlanHeight] = useState('100vh');
    const [providers, setProviders] = useState([]);
    const [plans, setPlans] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState('');
    const [selectedPlan, setSelectedPlan] = useState('');
    const [schedules, setSchedules] = useState([]);
    const [, setOnBoardingSchedule] = useRecoilState(onboardingScheduleAtom);
    const navigate = useNavigate();
    const customerState = useRecoilValue(customerStateAtom);
    const [onboardingPreviousPage, setOnboardingPreviousPage] = useRecoilState(
        onboardingPreviousPageAtom
    );
    const [, setOnBoardingPageCurrentlyShown] = useRecoilState(onboardingPageCurrentlyShownAtom);
    const location = useLocation();
    const [showPCTNotSet, setShowPCTNotSet] = useState(false);
    let zipCode;

    useEffect(() => {
        if (
            customerState != null &&
            customerState.location != null &&
            customerState.location.zipCode != null
        ) {
            zipCode = customerState.location.zipCode;
            getUtilityProviders(zipCode);
        } else {
            navigate('/error', { state: ERROR_STATUS.Unexpected });
        }
    }, []);

    useBackButtonHandler(location, goBackToPreviousPage);

    function goBackToPreviousPage() {
        if (onboardingPreviousPage === OnBoardingPagesConst.EDIT_SCHEDULE) {
            setOnboardingPreviousPage(OnBoardingPagesConst.UTILITY_PLAN);
            setOnBoardingPageCurrentlyShown(OnBoardingPagesConst.EDIT_SCHEDULE);
        } else if (onboardingPreviousPage === OnBoardingPagesConst.ONBOARD_READY) {
            setOnboardingPreviousPage(OnBoardingPagesConst.EDIT_SCHEDULE);
            setOnBoardingPageCurrentlyShown(OnBoardingPagesConst.ONBOARD_READY);
        }
    }

    function getUtilityProviders(zipCode) {
        const url = new URL(config.VEHICLE_STATUS_API_URL + '/energy-app/v1/utility-providers');
        url.search = new URLSearchParams({ zipCode }).toString();
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: { 'Auth-Token': sessionStorage.getItem('catToken') }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Internal Server Error');
                }
            })
            .then((data) => {
                if (Array.isArray(data) && data.length) {
                    setProviders(data);
                    if (data.length === 1) {
                        setSelectedProvider(data[0].providerId);
                        getUtilityPlans(data[0].providerId);
                    }
                }
                trackEvent(AMPLITUDE_EVENTS.VIEW_UTILITY_SCREEN);
                setLoading(false);
            })
            .catch((error) => {
                trackEvent(AMPLITUDE_EVENTS.IP_ERROR);
                if (isEditFlow) {
                    console.log('got here');
                    setLoading(false);
                    setShowPCTNotSet(true);
                } else {
                    navigate('/error', { state: ERROR_STATUS.Unexpected });
                }
            });
    }

    function getUtilityPlans(providerId) {
        fetch(
            config.VEHICLE_STATUS_API_URL + `/energy-app/v1/utility-providers/${providerId}/plans`,
            {
                method: 'GET',
                mode: 'cors',
                headers: { 'Auth-Token': sessionStorage.getItem('catToken') }
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Internal Server Error');
                }
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    setPlans(data);
                }
            })
            .catch((error) => {
                trackEvent(AMPLITUDE_EVENTS.IP_ERROR);
                navigate('/error', { state: ERROR_STATUS.Unexpected });
            });
    }

    function getPlanDetails(providerId, planId) {
        fetch(
            config.VEHICLE_STATUS_API_URL +
                `/energy-app/v1/utility-providers/${providerId}/plans/${planId}`,
            {
                method: 'GET',
                mode: 'cors',
                headers: { 'Auth-Token': sessionStorage.getItem('catToken') }
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Internal Server Error');
                }
            })
            .then((data) => {
                setSchedules(data.schedules);
            })
            .catch((error) => {
                trackEvent(AMPLITUDE_EVENTS.IP_ERROR);
                navigate('/error', { state: ERROR_STATUS.Unexpected });
            });
    }

    function handleProviderSelect(key) {
        setSelectedProvider(key);
        setSelectedPlan('');
        setSchedules([]);
        setPlans([]);
        getUtilityPlans(key);
    }

    function handlePlanSelect(key) {
        setSelectedPlan(key);
        getPlanDetails(selectedProvider, key);
    }

    function getSelectedPlanName() {
        const selected = plans.find((plan) => plan.planId === selectedPlan);
        return selected ? selected.planName : 'Select plan';
    }

    function getSelectedProviderName() {
        const selected = providers.find((provider) => provider.providerId === selectedProvider);
        return selected ? selected.providerName : 'Select provider';
    }

    function getFormattedSchedule(startTime, endTime) {
        return `${convertTo12HourFormat(startTime)} - ${convertTo12HourFormat(endTime)}`;
    }

    function convertTo12HourFormat(time24) {
        const [hours24, minutes] = time24.split(':');
        const hours = parseInt(hours24, 10);
        const period = hours >= 12 ? 'pm' : 'am';
        const hours12 = hours % 12 || 12;
        return `${hours12}:${minutes} ${period}`;
    }

    function getPeakSchedule(dayType) {
        if (schedules.length > 0) {
            if (dayType === 'WEEKDAY') {
                const weekdaySchedule = schedules.find((schedule) => schedule.days === 'WEEKDAY');
                return getFormattedSchedule(
                    weekdaySchedule.offPeakWindows[0].endTime,
                    weekdaySchedule.offPeakWindows[0].startTime
                );
            } else if (dayType === 'WEEKEND') {
                const weekendSchedule = schedules.find((schedule) => schedule.days === 'WEEKEND');
                return getFormattedSchedule(
                    weekendSchedule.offPeakWindows[0].endTime,
                    weekendSchedule.offPeakWindows[0].startTime
                );
            }
        }
    }

    function getOffPeakSchedule(dayType) {
        if (schedules.length > 0) {
            if (dayType === 'WEEKDAY') {
                const weekdaySchedule = schedules.find((schedule) => schedule.days === 'WEEKDAY');
                return getFormattedSchedule(
                    weekdaySchedule.offPeakWindows[0].startTime,
                    weekdaySchedule.offPeakWindows[0].endTime
                );
            } else if (dayType === 'WEEKEND') {
                const weekendSchedule = schedules.find((schedule) => schedule.days === 'WEEKEND');
                return getFormattedSchedule(
                    weekendSchedule.offPeakWindows[0].startTime,
                    weekendSchedule.offPeakWindows[0].endTime
                );
            }
        }
    }

    function planOverview(dayType) {
        return (
            <Stack direction="vertical">
                <Stack direction="horizontal">
                    <Stack direction="vertical">
                        <p className="plan-type peak"> PEAK SUMMER </p>
                        <p> $$$ </p>
                    </Stack>
                    <Stack direction="vertical" className=".plan-details">
                        <p className="plan-hours">{getPeakSchedule(dayType)}</p>
                        <p> {dayType === 'WEEKDAY' ? 'Weekdays' : 'Weekend'} </p>
                    </Stack>
                </Stack>
                <Stack direction="horizontal">
                    <Stack direction="vertical">
                        <p className="plan-type off-peak"> OFF-PEAK SUMMER</p>
                        <p> $ </p>
                    </Stack>
                    <Stack direction="vertical" className=".plan-details">
                        <p className="plan-hours">{getOffPeakSchedule(dayType)}</p>
                        <p> {dayType === 'WEEKDAY' ? 'Weekdays' : 'Weekend'} </p>
                    </Stack>
                </Stack>
            </Stack>
        );
    }

    const handleTabSelect = (key) => {
        trackEvent(AMPLITUDE_EVENTS.IP_ONBOARD_UTILITY_TAB_SWITCH, {
            Timestamp: new Date().toISOString(),
            tab: key
        });
    };

    function weekdayAndWeekendPlanOverview() {
        return (
            <Tabs
                defaultActiveKey="Weekday"
                id="fill-tab"
                className="info-tabs mb-3"
                fill
                onSelect={handleTabSelect}
            >
                <Tab eventKey="Weekday" title="Weekday">
                    {planOverview('WEEKDAY')}
                </Tab>
                <Tab eventKey="Weekend" title="Weekend">
                    {planOverview('WEEKEND')}
                </Tab>
            </Tabs>
        );
    }

    function createPlanOverview() {
        let validWeekendSchedule;
        const weekendSchedule = schedules.find((schedule) => schedule.days === 'WEEKEND');
        if (weekendSchedule && weekendSchedule.offPeakWindows.length > 0) {
            const { startTime, endTime } = weekendSchedule.offPeakWindows[0];
            const [startHours, startMinutes] = startTime.split(':').map(Number);
            const [endHours, endMinutes] = endTime.split(':').map(Number);
            const startTotalMinutes = startHours * 60 + startMinutes;
            const endTotalMinutes = endHours * 60 + endMinutes;
            let gapInMinutes = (endTotalMinutes - startTotalMinutes + 24 * 60) % (24 * 60);
            validWeekendSchedule = gapInMinutes > 1;
        }
        if (validWeekendSchedule) {
            return <div className="plan-overview">{weekdayAndWeekendPlanOverview()}</div>;
        } else {
            return (
                <div className="plan-overview">
                    <p className="plan-overview"> Plan Overview</p>
                    {planOverview('WEEKDAY')}
                </div>
            );
        }
    }

    function handleContinueClick() {
        trackEvent(AMPLITUDE_EVENTS.IP_ONBOARD_UTILITY_CONTINUE);
        const weekdaySchedule = schedules.find((schedule) => schedule.days === 'WEEKDAY');
        const weekendSchedule = schedules.find((schedule) => schedule.days === 'WEEKEND');
        let weekdayOnboardingSchedule;
        let weekendOnBoardingSchedule;

        if (weekdaySchedule && weekdaySchedule.offPeakWindows.length > 0) {
            weekdayOnboardingSchedule = {
                dischargeList: [
                    {
                        startTime: weekdaySchedule.offPeakWindows[0].endTime,
                        endTime: weekdaySchedule.offPeakWindows[0].startTime
                    }
                ]
            };
        }

        if (weekendSchedule && weekendSchedule.offPeakWindows.length > 0) {
            weekendOnBoardingSchedule = {
                dischargeList: [
                    {
                        startTime: weekendSchedule.offPeakWindows[0].endTime,
                        endTime: weekendSchedule.offPeakWindows[0].startTime
                    }
                ]
            };
        }

        setOnBoardingSchedule({
            utilityId: selectedProvider,
            planId: selectedPlan,
            weekdays: weekdayOnboardingSchedule,
            weekends: weekendOnBoardingSchedule
        });

        setOnboardingPreviousPage(OnBoardingPagesConst.UTILITY_PLAN);
        setOnBoardingPageCurrentlyShown(OnBoardingPagesConst.ONBOARD_READY);
    }

    const handleContactUsClick = (event) => {
        event.preventDefault();
        trackEvent(AMPLITUDE_EVENTS.IP_ONBOARD_NEED_HELP);
        window.location.href = { MAILTO_LINK };
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const dropdownMenu = document.querySelector('.dropdown-menu.show');
            const dropdownHeight = dropdownMenu ? dropdownMenu.offsetHeight : 0;
            const viewportHeight = Math.max(
                document.documentElement.clientHeight || 0,
                window.innerHeight || 0
            );
            const totalHeight = Math.min(scrollPosition + viewportHeight, dropdownHeight) + 350;
            const totalHeightInVh = (totalHeight / window.innerHeight) * 100;
            setUtilityPlanHeight(totalHeightInVh > 100 ? `${totalHeight}px` : '100vh');
        };

        if (isUtilityPlanDropdownOpen) {
            window.addEventListener('scroll', handleScroll);
        } else {
            setUtilityPlanHeight(window.innerHeight <= 785 ? '100%' : '100vh');
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isUtilityPlanDropdownOpen]);

    function handlePctNotSetClose() {
        setShowPCTNotSet(false);
        exitOnboarding();
    }

    if (loading) {
        return <LoadingScreen text="Loading..." />;
    } else if (showPCTNotSet) {
        return (
            <PCTNotSet
                data-testid="pct-not-set"
                showCloseButton={true}
                closeButton={handlePctNotSetClose}
            />
        );
    } else {
        return (
            <div>
                <div className="utility-plan" style={{ height: utilityPlanHeight }}>
                    {isEditFlow && (
                        <button className="arrow-button" onClick={exitOnboarding}>
                            <img src={BackButton} alt="Back Button" />
                        </button>
                    )}
                    <div className="utility-plan-header">
                        <h1>Your Utility Plan</h1>
                        <p>
                            We’ll use local electricity rates to set your Home Power Management
                            schedule.
                        </p>
                    </div>
                    <div className="utility-plan-selectors">
                        <Dropdown onSelect={handleProviderSelect}>
                            <Dropdown.Header>Utility Provider</Dropdown.Header>
                            <Dropdown.Toggle className="utility-provider">
                                {getSelectedProviderName()}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {providers.map((provider, index) => (
                                    <Dropdown.Item eventKey={provider.providerId} key={index}>
                                        {provider.providerName}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown
                            onSelect={handlePlanSelect}
                            onToggle={(isOpen) => setIsUtilityPlanDropdownOpen(isOpen)}
                        >
                            <Dropdown.Header>Utility Plan</Dropdown.Header>
                            <Dropdown.Toggle className="utility-plan">
                                {getSelectedPlanName()}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {plans.map((plan, index) => (
                                    <Dropdown.Item key={index} eventKey={plan.planId}>
                                        {plan.planName}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {schedules.length > 0 ? createPlanOverview() : null}
                    <div className="utility-plan-footer">
                        <Stack direction="vertical">
                            <Button
                                className="action-button primary"
                                onClick={() => {
                                    handleContinueClick();
                                }}
                            >
                                Continue
                            </Button>
                            <Button
                                className="action-button secondary"
                                onClick={() => {
                                    trackEvent(AMPLITUDE_EVENTS.IP_ONBOARD_PLAN_NOT_AVAILABLE);
                                    setOnBoardingPageCurrentlyShown(
                                        OnBoardingPagesConst.EDIT_SCHEDULE
                                    );
                                }}
                            >
                                {"My plan isn't an option"}
                            </Button>
                        </Stack>
                        <div className="utility-plan-contact-link">
                            <span>
                                Need help?{' '}
                                <a href={MAILTO_LINK} onClick={handleContactUsClick}>
                                    Contact Us.
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
