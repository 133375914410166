import React, { useEffect, useState } from 'react';
import './HomePage.scss';
import SummaryHero from '../../components/SummaryHero/SummaryHero';
import ChargeSettings from '../../components/ChargeSettings/ChargeSettings';
import BackupPowerTile from '../../components/BackupPowerTile/BackupPowerTile';
import UserType from '../../constants/UserTypeConst';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { loginStartTimeAtom, customerStateAtom } from '../../atoms/customerState.js';
import ERROR_STATUS from '../../constants/ErrorStatusConst';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import config from '../../env/environment.js';
import { trackEvent, AMPLITUDE_EVENTS, identifyUser } from '../../util/Amplitude';
import OnBoardingPage from '../HomePowerManagement/OnBoardingPage';
import ChargeInformation from '../../components/ChargeInformation/ChargeInformation.jsx';
import PrivacyLinks from '../../components/PrivacyLinks/PrivacyLinks.jsx';
import { fetchLoadingAtom } from '../../atoms/vehicleState.js';
import { RefreshCustomerStatusContext } from './RefreshCustomerStatusContext.jsx';

const HomePage = () => {
    const [loading, setLoading] = useState(true);
    const [, setVehicleLoading] = useRecoilState(fetchLoadingAtom);
    const authState = sessionStorage.getItem('FMA-Authentication');
    const [customerState, setCustomerState] = useRecoilState(customerStateAtom);
    const [, setLoginStartTime] = useRecoilState(loginStartTimeAtom);
    const navigate = useNavigate();

    const getCustomerInfo = () => {
        fetch(config.VEHICLE_STATUS_API_URL + '/energy-app/v1/customer', {
            method: 'GET',
            mode: 'cors',
            headers: { 'Auth-Token': sessionStorage.getItem('catToken') }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response);
                }
            })
            .then((data) => {
                if (data.vin) {
                    setCustomerState(data);
                    sessionStorage.setItem('user', JSON.stringify(data));
                    sessionStorage.setItem('VIN', data.vin);
                    identifyUser(data.vin, data.userType);
                    setLoading(false);
                } else if (data.error === 'Unauthorized') {
                    navigate('/welcome');
                } else {
                    setCustomerState(data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                trackEvent(AMPLITUDE_EVENTS.LOGIN_FAILED, {
                    Timestamp: new Date().toISOString(),
                    error: 'Customer API failed'
                });
                navigate('/error', { state: ERROR_STATUS.Unexpected });
            });
    };

    useEffect(() => {
        refreshCustomerStatus();
    }, []);

    const refreshCustomerStatus = () => {
        setVehicleLoading(true);
        setLoading(true);
        setLoginStartTime(new Date().getTime());
        getCustomerInfo();
    };

    if (authState === 'authenticated') {
        if (loading) {
            return <LoadingScreen text="Verifying..." />;
        } else if (
            customerState.customerStatus === 'HOME' ||
            customerState.customerStatus === 'POWER OUTAGE'
        ) {
            return (
                <RefreshCustomerStatusContext.Provider value={{ refreshCustomerStatus }}>
                    <SummaryHero
                        data-testid="summary-hero-component"
                        userType={customerState.userType}
                        customerStatus={customerState.customerStatus}
                        vehicleImage={customerState.vehicleImage}
                    />
                    <ChargeSettings data-testid="charge-settings-component" isSideMenu={false} />
                    {customerState.userType !== UserType.GRID && (
                        <BackupPowerTile
                            data-testid="backup-power-component"
                            userType={customerState.userType}
                        />
                    )}
                    <ChargeInformation
                        data-test-id="charge-information-component"
                        userType={customerState.userType}
                    />
                    <PrivacyLinks data-test-id="privacy-links" />
                    {/*//TODO might want to put privacy links outside of the if/else as it will be needed in edit range reserve as well*/}
                </RefreshCustomerStatusContext.Provider>
            );
        } else {
            return (
                <RefreshCustomerStatusContext.Provider value={{ refreshCustomerStatus }}>
                    <OnBoardingPage onboardStatus={customerState.customerStatus} />
                </RefreshCustomerStatusContext.Provider>
            );
        }
    }
};

export default HomePage;
