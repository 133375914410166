import * as Amplitude from '@amplitude/analytics-browser';
import config from '../env/environment.js';

export const AMPLITUDE_EVENTS = {
    LOGIN_SUCCESS: 'EA-Successful Login',
    LOGIN_FAILED: 'EA-Unsuccessful Login',
    VIEW_HOMESCREEN: 'EA-View Homescreen',
    VIEW_UTILITY_SCREEN: 'EA-View UtilityScreen',
    EDIT_TARGET_CHARGE: 'EA-Target Charge Icon Tapped',
    EDIT_DEPARTURE_TIME: 'EA-Departure Times Icon Tapped',
    EDIT_RANGE_RESERVE: 'EA-Edit Range Reserve Icon Tapped',
    EDIT_RANGE_RESERVE_BACK: 'EA-EDIT-RANGE-RESERVE-BACK-CLICKED',
    EDIT_RANGE_RESERVE_PLUS: 'EA-EDIT-RANGE-RESERVE-PLUS-CLICKED',
    EDIT_RANGE_RESERVE_MINUS: 'EA-EDIT-RANGE-RESERVE-MINUS-CLICKED',
    EDIT_RANGE_RESERVE_SLIDER_CHANGED: 'EA-EDIT-RANGE-RESERVE-SLIDER-VALUE-CHANGED',
    EDIT_RANGE_RESERVE_EDIT_MODE_TOGGLE: 'EA-EDIT-RANGE-RESERVE-EDIT-MODE-TOGGLE-CHANGED',
    EXPAND_SCHEDULE: 'EA-Expand Schedule',
    SKIP_SCHEDULE: 'EA-Confirm Skip Schedule',
    IP_DISCONNECTED: 'EA-IP-DISCONNECTED',
    IP_PCT_NOT_SET: 'EA-IP-PCT-NOT-SET',
    IP_ERROR: 'EA-IP-ERROR',
    IP_HOME_SCREEN: 'EA-IP-HOME-SCREEN',
    IP_LOGIN_SUCCESS: 'EA-IP-LOGIN-SUCCESS',
    IP_ONBOARD_READY: 'EA-IP-ONBOARD-READY',
    IP_OPTED_OUT: 'EA-IP-OPTED-OUT',
    IP_STOP_TRANSFER: 'EA-IP-STOP-TRANSFER',
    IP_START_TRANSFER: 'EA-IP-START-TRANSFER',
    STOP_CHARGING: 'EA-STOP-CHARGING',
    START_CHARGING: 'EA-START-CHARGING',
    IP_ONBOARD_UTILITY_CONTINUE: 'EA-IP-ONBOARD-UTILITY-CONTINUE-CLICKED',
    IP_ONBOARD_PLAN_NOT_AVAILABLE: 'EA-IP-ONBOARD-PLAN-NOT-AVAILABLE-CLICKED',
    IP_ONBOARD_NEED_HELP: 'EA-IP-ONBOARD-NEED-HELP-CLICKED',
    IP_ONBOARD_UTILITY_TAB_SWITCH: 'EA-IP-ONBOARD-UTILITY-TAB-SWITCH',
    IP_EDIT_SCHEDULE_TAB_SWITCH: 'EA-IP-EDIT-SCHEDULE-TAB-SWITCH',
    IP_EDIT_SCHEDULE_WEEKEND_TOGGLE: 'EA-IP-EDIT-SCHEDULE-WEEKEND-TOGGLE',
    IP_EDIT_SCHEDULE_BACK: 'EA-IP-EDIT-SCHEDULE-BACK-CLICKED',
    IP_EDIT_SCHEDULE_FORWARD: 'EA-IP-EDIT-SCHEDULE-FORWARD-CLICKED',
    IP_ONBOARD_READY_BACK: 'EA-IP-ONBOARD-READY-BACK-CLICKED',
    IP_ONBOARD_READY_CONFIRM: 'EA-IP-ONBOARD-READY-CONFIRM-CLICKED',
    IP_ONBOARD_READY_TAB_SWITCH: 'EA-IP-ONBOARD-READY-TAB-SWITCH',
    IP_EDIT_SCHEDULE_CLICKED: 'EA-IP-EDIT_SCHEDULE_CLICK',
    EDIT_TARGET_CHARGE_BACK: 'EA-EDIT_TARGET_CHARGE_BACK_CLICK',
    EDIT_TARGET_CHARGE_PLUS: 'EA-EDIT_TARGET_CHARGE_PLUS_CLICK',
    EDIT_TARGET_CHARGE_MINUS: 'EA-EDIT_TARGET_CHARGE_MINUS_CLICK',
    EDIT_TARGET_CHARGE_SLIDER: 'EA-EDIT_TARGET_CHARGE_SLIDER_CLICK',
    EDIT_RANGE_RESERVE_SIDE_MENU: 'EA-EDIT_RANGE_RESERVE_SIDE_MENU_CLICK',
    IP_EDIT_SCHEDULE_CLICKED_SIDE_MENU: 'EA-IP-EDIT_SCHEDULE_SIDE_MENU_CLICK',
    EDIT_TARGET_CHARGE_SIDE_MENU: 'EA-EDIT_TARGET_CHARGE_SIDE_MENU_CLICK'
};

export const initAmplitude = () => {
    Amplitude.init(config.AMPLITUDE_API_KEY, {
        defaultTracking: {
            attribution: false,
            pageViews: false,
            sessions: false,
            formInteractions: false,
            fileDownloads: false
        }
    });
};

export const identifyUser = (VIN, userType) => {
    Amplitude.setUserId(VIN);
    Amplitude.setGroup('User Type', userType);
};

export const trackEvent = (eventName, eventProps = { Timestamp: new Date().toISOString() }) => {
    Amplitude.track(eventName, eventProps);
};
