import './SummaryHero.scss';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Logout from '../../util/Logout.js';
import BatteryGauge from 'react-battery-gauge';
import SideMenu from '../SideMenu/SideMenu';
import config from '../../env/environment.js';
import VEHICLE_STATUS from '../../constants/VehicleStatusConst.js';
import ERROR_STATUS from '../../constants/ErrorStatusConst';
import { Button, Col, Modal, Stack } from 'react-bootstrap';
import {
    chargeDoneTimeAtom,
    vehicleStateAtom,
    vehicleStatusAtom,
    fetchLoadingAtom,
    powerOutageAtom
} from '../../atoms/vehicleState';
import { useRecoilState, useRecoilValue } from 'recoil';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import EnergyFlow from '../EnergyFlowDiagram/EnergyFlow';
import { loginStartTimeAtom, customerStateAtom } from '../../atoms/customerState.js';
import { AMPLITUDE_EVENTS, trackEvent } from '../../util/Amplitude.js';
import IpScheduleUpdateStatusConst from '../../constants/IpScheduleUpdateStatusConst.js';
import { RefreshCustomerStatusContext } from '../../pages/HomePage/RefreshCustomerStatusContext.jsx';
import { MAILTO_LINK } from '../../constants/HelpLinks.js';

export default function SummaryHero({ userType, customerStatus, vehicleImage }) {
    const navigate = useNavigate();
    const VIN = sessionStorage.getItem('VIN');

    const [loading, setLoading] = useRecoilState(fetchLoadingAtom);
    const [loginStartTime, setLoginStartTime] = useRecoilState(loginStartTimeAtom);
    const [vehicleState, setVehicleState] = useRecoilState(vehicleStateAtom);
    const [vehicleStatus, setVehicleStatus] = useRecoilState(vehicleStatusAtom);
    const vehicleChargeDoneTime = useRecoilValue(chargeDoneTimeAtom);
    const customerState = useRecoilValue(customerStateAtom);
    const [showScheduleUpdatePopup, setShowScheduleUpdatePopup] = useState(false);
    const [hasCompletionTime, setHasCompletionTime] = useState(false);
    const refreshStatusContext = useContext(RefreshCustomerStatusContext);
    const [powerOutage, setPowerOutage] = useRecoilState(powerOutageAtom);

    moment.defineLocale('custom-locale', {
        parentLocale: 'en',
        calendar: {
            lastDay: '[Yesterday at] LT',
            sameDay: '[Today at] LT',
            nextDay: '[Tomorrow at] LT',
            lastWeek: '[Last] dddd [at] LT',
            nextWeek: 'dddd [at] LT',
            sameElse: 'dddd [at] LT'
        }
    });

    function trackLoginTime() {
        const loginEndTime = new Date().getTime();
        const loginTime = ((loginEndTime - loginStartTime) / 1000).toFixed(2);
        trackEvent(AMPLITUDE_EVENTS.VIEW_HOMESCREEN, {
            Timestamp: new Date().toISOString(),
            loginTime: loginTime,
            powerOutage: vehicleState.powerOutage
        });
        setLoginStartTime(0);
    }

    function trackLoginFailure(logError, errorReason) {
        if (logError) {
            trackEvent(AMPLITUDE_EVENTS.LOGIN_FAILED, {
                Timestamp: new Date().toISOString(),
                error: errorReason
            });
        }
    }

    const getVehicleStatus = (isFirstPageLoad) => {
        fetch(config.VEHICLE_STATUS_API_URL + `/energy-app/v1/vehicle-status/${VIN}`, {
            method: 'GET',
            mode: 'cors',
            headers: { 'Auth-Token': sessionStorage.getItem('catToken') }
        })
            .then((response) => {
                if (response.status === 401) {
                    throw new Error('Unauthorized');
                } else if (response.status === 403) {
                    throw new Error('Forbidden');
                } else if (response.status === 500) {
                    throw new Error('Internal Server Error');
                }
                return response.json();
            })
            .then((data) => {
                setVehicleState(data);
                setVehicleStatus(data.chargingStatusEnums);
                if (
                    data.eventCompletionTime &&
                    data.eventCompletionTime !== 'No charge events equal target state of charge.'
                ) {
                    setHasCompletionTime(true);
                } else {
                    setHasCompletionTime(false);
                }
                if (isFirstPageLoad) {
                    trackLoginTime();
                    showScheduleUpdateStatus();
                }
                setLoading(false);
            })
            .catch((error) => {
                if (error.message === 'Unauthorized') {
                    trackLoginFailure(isFirstPageLoad, 'Unauthorized');
                    Logout();
                } else if (error.message === 'Internal Server Error') {
                    trackLoginFailure(isFirstPageLoad, 'Unknown error');
                    navigate('/error', { state: ERROR_STATUS.FailedToFetchVehicleStatus });
                } else if (error.message === 'Forbidden') {
                    trackLoginFailure(isFirstPageLoad, 'No access');
                    navigate('/error', { state: ERROR_STATUS.NoAccess });
                } else {
                    trackLoginFailure(isFirstPageLoad, 'Unknown error');
                    navigate('/error', { state: ERROR_STATUS.Unexpected });
                }
            });
    };

    useEffect(() => {
        getVehicleStatus(true);
        const interval = setInterval(() => {
            getVehicleStatus(false);
        }, 20000); // fetch every 20 seconds
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (vehicleState) {
            if (customerStatus === 'POWER OUTAGE') {
                if (vehicleState.powerOutage !== powerOutage) {
                    setPowerOutage(vehicleState.powerOutage);
                    refreshStatusContext.refreshCustomerStatus();
                }
            }
        }
    }, [vehicleState]);

    const showScheduleUpdateStatus = () => {
        if (customerState.ipScheduleStatus === IpScheduleUpdateStatusConst.REJECTED) {
            setShowScheduleUpdatePopup(true);
        }
    };

    const renderEventTimeCompletion = () => {
        if (hasCompletionTime) {
            if (
                [
                    VEHICLE_STATUS.Charging,
                    VEHICLE_STATUS.Waiting,
                    VEHICLE_STATUS.WaitingToCharge
                ].includes(vehicleStatus)
            ) {
                const timeStampArray = moment(vehicleState.eventCompletionTime)
                    .locale('custom-locale')
                    .calendar()
                    .split(' ');

                return (
                    <>
                        <div className="event-completion-text">{vehicleState.eventTitle}</div>
                        <div className="time-text">
                            {timeStampArray[2]}
                            <span className="text-xl ml-2">{timeStampArray[3]}</span>
                        </div>
                        <div className="event-completion-text">{timeStampArray[0]}</div>
                    </>
                );
            } else if (vehicleStatus === VEHICLE_STATUS.PoweringHome && vehicleState.powerOutage) {
                const timeRemainingInMins = parseInt(vehicleState.eventCompletionTime);
                if (timeRemainingInMins !== 0) {
                    const timeRemainingText =
                        convertMinutesToDaysHoursAndMinutes(timeRemainingInMins);
                    return formatTimeRemainingText(vehicleState.eventTitle, timeRemainingText);
                }
            } else {
                const timeRemainingText = getTimeDifferenceInDaysHoursAndMinutes(
                    vehicleState.eventCompletionTime
                );
                return formatTimeRemainingText(vehicleState.eventTitle, timeRemainingText);
            }
        } else if (vehicleStatus === VEHICLE_STATUS.OutageDetected) {
            return (
                <div className="event-completion-text outage-detected-text">
                    {vehicleState.eventTitle}
                </div>
            );
        } else {
            return <div className="time-text">{vehicleChargeDoneTime}</div>;
        }
    };

    if (loading) {
        return <LoadingScreen text="Loading..." />;
    } else {
        return (
            <div className="main-container">
                <div className={`dynamic-background ${STYLE_CLASS[vehicleStatus]}`}>
                    <Col>
                        <Stack className="summary-stack" gap={2}>
                            <div className="side-menu-toggle">
                                <SideMenu data-testid="side-menu" />
                            </div>

                            <div className="summary-stack-row plug-status-text">
                                <div
                                    className={`plug-status-icon ${STYLE_CLASS[vehicleStatus]}-icon`}
                                />
                                <div>{vehicleStatus}</div>
                            </div>

                            <div className="summary-stack-row charge-complete-container">
                                {vehicleStatus !== VEHICLE_STATUS.NotPluggedIn && (
                                    <>{renderEventTimeCompletion()}</>
                                )}
                            </div>

                            <div className="summary-stack-row state-of-charge">
                                <h1 className="battery-level-text">
                                    {vehicleState.batteryStateOfCharge}
                                    <span className="text-xl ml-2">%</span>
                                </h1>
                                <div className="battery-icon">
                                    <BatteryGauge
                                        value={vehicleState.batteryStateOfCharge}
                                        size={23}
                                        customization={BATTERY_ICON_STYLE}
                                    />
                                </div>
                            </div>
                        </Stack>
                    </Col>
                    <Col>
                        {vehicleImage && (
                            <>
                                <div className="cropped-container">
                                    <EnergyFlow
                                        chargingStatus={vehicleStatus}
                                        userType={userType}
                                        powerOutage={vehicleState.powerOutage}
                                    />
                                    <img
                                        className="vehicle-img"
                                        src={'data:image/png;base64,' + vehicleImage}
                                        alt="vehicle"
                                    />
                                </div>
                            </>
                        )}
                    </Col>
                </div>
                <Modal
                    show={showScheduleUpdatePopup}
                    onHide={() => setShowScheduleUpdatePopup(false)}
                    animation={true}
                    centered
                >
                    <Modal.Header closeButton closeVariant="white">
                        <span>Update Schedule Failed</span>
                    </Modal.Header>
                    <Modal.Body>
                        Your previous schedule is still in effect.
                        <Button className="contact-button">
                            <a href={MAILTO_LINK}>Contact Us.</a>
                        </Button>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

function formatTimeRemainingText(eventTitle, timeRemainingText) {
    const timeRemainingTextArr = timeRemainingText.split(' ');
    return (
        <>
            <div className="event-completion-text">{eventTitle}</div>
            <div className="time-text flex">
                <div className="time-remain-text">
                    {timeRemainingTextArr.map((timeText, index) => {
                        return <span key={index}>{timeText}</span>;
                    })}
                </div>
            </div>
        </>
    );
}

function convertMinutesToDaysHoursAndMinutes(minutes) {
    const duration = moment.duration(minutes, 'minutes');

    return getTimeInDaysHoursMinutesFormat(duration);
}

function getTimeDifferenceInDaysHoursAndMinutes(utcString) {
    const currentTime = moment();
    const givenTime = moment(utcString);
    const duration = moment.duration(givenTime.diff(currentTime));

    return getTimeInDaysHoursMinutesFormat(duration);
}

function getTimeInDaysHoursMinutesFormat(duration) {
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    let result = '';
    if (days > 0) {
        result += `${days} d `;
    }
    if (hours > 0) {
        if (hours === 1) {
            result += `${hours} hr `;
        } else {
            result += `${hours} hrs `;
        }
    }
    if (days === 0 && minutes > 0) {
        if (minutes === 1) {
            result += `${minutes} min`;
        } else {
            result += `${minutes} mins`;
        }
    }
    return result.trim();
}

const STYLE_CLASS = {
    Charging: 'charging',
    Waiting: 'waiting-to-charge',
    'Waiting to Charge': 'waiting-to-charge',
    'Not Plugged In': 'not-plugged-in',
    'Ready to go': 'ready-to-go',
    'Powering Home': 'powering-home',
    'Outage Detected': 'outage-detected'
};

const BATTERY_ICON_STYLE = {
    batteryBody: {
        strokeWidth: 4,
        cornerRadius: 6,
        strokeColor: 'white'
    },
    batteryCap: {
        fill: 'white',
        strokeWidth: 4,
        strokeColor: 'white',
        cornerRadius: 2,
        capToBodyRatio: 0.4
    },
    batteryMeter: {
        fill: 'white',
        lowBatteryValue: 15,
        outerGap: 6
    },
    readingText: {
        display: 'none'
    }
};
