import React, { useRef, useState } from 'react';
import { Button, Stack } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';
import MenuButtonIcon from '../../assets/icons/SIDE-MENU/Menu_icon.svg';
import { customerStateAtom } from '../../atoms/customerState.js';
import { useRecoilState, useRecoilValue } from 'recoil';
import Logout from '../../util/Logout';
import './SideMenu.scss';
import UserType from '../../constants/UserTypeConst';
import { MAILTO_LINK } from '../../constants/HelpLinks.js';
import EditRangeReserve from '../EditRangeReserve/EditRangeReserve.jsx';
import EditScheduleModal from '../OnBoardingContainer/EditScheduleModal.jsx';
import EditTargetCharge from '../EditTargetCharge/EditTargetCharge.jsx';
import ChargeSettings, { editTargetCharge } from '../ChargeSettings/ChargeSettings.jsx';
import { showSideMenuAtom } from '../../atoms/vehicleState.js';

export default function SideMenu() {
    const [show, setShow] = useRecoilState(showSideMenuAtom);
    const customerState = useRecoilValue(customerStateAtom);
    const isFordEnergyRewardsUser =
        customerState.userType === UserType.GRID ||
        customerState.userType === UserType.GRID_HOME_BACKUP_POWER;
    const isBackupUser =
        customerState.userType === UserType.GRID_HOME_BACKUP_POWER ||
        customerState.userType === UserType.HOME_BACKUP_POWER ||
        customerState.userType === UserType.HOME_POWER_MANAGEMENT;
    const isHomePowerManagementUser = customerState.userType === UserType.HOME_POWER_MANAGEMENT;
    const appVersion = require('../../../package.json').version;

    return (
        <>
            <Button className="menu-toggle-button" variant="" onClick={() => setShow(true)}>
                <div>
                    <img src={MenuButtonIcon} className="menu-button-icon" alt="Menu Toggle" />
                </div>
            </Button>
            <Offcanvas show={show} onHide={() => setShow(false)}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Hello {customerState.firstName}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Stack>
                        {/*{isFordEnergyRewardsUser && (*/} {/*//TODO UPDATE WHEN WE GET NEW LINK*/}
                        {/*    <div className="menu-item">*/}
                        {/*        <a*/}
                        {/*            className="menu-item__text"*/}
                        {/*            href={`https://www.ford.com/grid/${customerState.programs.utility}/dashboard`}*/}
                        {/*            target="_blank"*/}
                        {/*            rel="noreferrer noopener"*/}
                        {/*        >*/}
                        {/*            Ford Energy Rewards*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        <div className="menu-item">
                            <a
                                className="menu-item__text"
                                href={MAILTO_LINK}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                Feedback
                            </a>
                        </div>
                        {!isFordEnergyRewardsUser && (
                            <div className="menu-item">
                                <a
                                    className="menu-item__text"
                                    href={`https://www.ford.com/support/how-tos/electric-vehicles/home-charging/f-150-lightning-intelligent-power-faqs/`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    Help Center
                                </a>
                            </div>
                        )}
                        {isBackupUser && <hr className="side-menu-divider"></hr>}
                        {isHomePowerManagementUser && (
                            <>
                                <div className="menu-item">
                                    <EditScheduleModal
                                        userType={customerState.userType}
                                        isHomePageFlow={false}
                                        data-testid="edit-schedule-modal"
                                    />
                                </div>
                                <div className="menu-item">
                                    <ChargeSettings
                                        data-testid="charge-settings-component"
                                        isSideMenu={true}
                                    />
                                </div>
                            </>
                        )}
                        {(isHomePowerManagementUser || isBackupUser) && (
                            <div className="menu-item">
                                <EditRangeReserve
                                    isHomePageFlow={false}
                                    data-testid="edit-range-reserve-modal"
                                />
                            </div>
                        )}
                        {(isFordEnergyRewardsUser || isBackupUser) && (
                            <hr className="side-menu-divider"></hr>
                        )}
                        {isFordEnergyRewardsUser && (
                            <div className="menu-item">
                                <a
                                    className="menu-item__text"
                                    href={`https://www.ford.com/support/category/electric-vehicle/ford-energy-rewards/`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    Ford Energy Rewards FAQs
                                </a>
                            </div>
                        )}
                        {isBackupUser && (
                            <div className="menu-item">
                                <a
                                    className="menu-item__text"
                                    href={`https://www.ford.com/support/category/electric-vehicle/home-backup-power/`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    Backup Power FAQs
                                </a>
                            </div>
                        )}
                        <hr className="side-menu-divider"></hr>
                        <div className="menu-item" onClick={Logout}>
                            Sign Out
                        </div>
                    </Stack>
                </Offcanvas.Body>
                <span className="version-number">Version: {appVersion}</span>
            </Offcanvas>
        </>
    );
}
