import React, { useEffect, useState } from 'react';
import { AMPLITUDE_EVENTS, trackEvent } from '../../util/Amplitude.js';
import EditIcon from '../../assets/icons/Edit_icon.svg';
import { Form, Modal } from 'react-bootstrap';
import BackButton from '../../assets/icons/Back_Arrow_Left_icon.svg';
import CircleButton from '../../assets/icons/Circle_icon.svg';
import { useRecoilState } from 'recoil';
import { showSideMenuAtom, vehicleStateAtom } from '../../atoms/vehicleState.js';
import config from '../../env/environment.js';
import './EditRangeReserve.scss';
import PrivacyLinks from '../PrivacyLinks/PrivacyLinks.jsx';

export default function EditRangeReserve({ isHomePageFlow }) {
    const [showEditRangeReserve, setShowEditRangeReserve] = useState(false);
    const [vehicleState] = useRecoilState(vehicleStateAtom);
    const [, setShowSideMenu] = useRecoilState(showSideMenuAtom);
    const [rangeReserve, setRangeReserve] = useState(vehicleState.reserve);
    const [automaticTransfer, setAutomaticTransfer] = useState(vehicleState.mode === 'AUTO');
    const currentRange = vehicleState.currentRange;
    const VIN = sessionStorage.getItem('VIN');

    const postEditRangeReserve = () => {
        const mode = automaticTransfer ? 'AUTO' : 'MANUAL';
        if (rangeReserve !== vehicleState.reserve || mode !== vehicleState.mode) {
            let requestBody = JSON.stringify({
                mode: mode,
                rangeReserve: rangeReserve
            });

            fetch(config.VEHICLE_STATUS_API_URL + `/energy-app/v1/edit-range-reserve/${VIN}`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Auth-Token': sessionStorage.getItem('catToken'),
                    'Content-Type': 'application/json'
                },
                body: requestBody
            });
        }
    };

    const handleSliderChange = (e) => {
        trackEvent(AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE_SLIDER_CHANGED);
        setRangeReserve(e.target.value);
    };

    const updateSliderValue = (value) => {
        const slider = document.querySelector('input[type="range"]');
        if (slider) {
            const percentage = ((value - 10) / (currentRange - 10)) * 100;
            slider.style.setProperty('--value', `${percentage}%`);
        }
    };

    const handlePlusButtonClick = () => {
        const newRangeReserve = Number(rangeReserve) + 1;
        setRangeReserve(newRangeReserve);
    };

    const handleMinusButtonClick = () => {
        const newRangeReserve = Number(rangeReserve) - 1;
        setRangeReserve(newRangeReserve);
    };

    useEffect(() => {
        updateSliderValue(rangeReserve);
    }, [rangeReserve]);

    useEffect(() => {
        updateSliderValue(rangeReserve);
    }, [showEditRangeReserve]);

    return (
        <div>
            {isHomePageFlow ? (
                <button
                    className="edit-button"
                    onClick={() => {
                        trackEvent(AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE);
                        setShowEditRangeReserve(true);
                    }}
                >
                    <img src={EditIcon} alt="Edit Range Reserve" />
                </button>
            ) : (
                <button
                    className="edit-button-side-menu"
                    onClick={() => {
                        trackEvent(AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE_SIDE_MENU);
                        setShowEditRangeReserve(true);
                    }}
                >
                    Edit Range Reserve
                </button>
            )}

            <Modal
                show={showEditRangeReserve}
                animation={true}
                centered
                className="edit-range-reserve-modal"
            >
                <Modal.Body>
                    <div className="edit-range-reserve">
                        <div className="edit-range-reserve-header">
                            <button
                                className="arrow-button-back"
                                onClick={() => {
                                    trackEvent(AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE_BACK);
                                    postEditRangeReserve();
                                    setShowEditRangeReserve(false);
                                    setShowSideMenu(false);
                                }}
                            >
                                <img src={BackButton} alt="Back Button" />
                            </button>
                            <p className="edit-range-reserve-header-title">Range Reserve</p>
                            <div className="edit-range-reserve-header-text">
                                <p>
                                    Transfers will stop when your vehicle reaches your selection. We
                                    recommend setting your energy reserve so that your vehicle has
                                    enough miles remaining to drive to a nearby charging station
                                </p>
                            </div>
                        </div>
                        <div className="edit-range-reserve-content">
                            <div className="edit-range-reserve-selector">
                                {rangeReserve > 10 && (
                                    <button
                                        className="range-reserve-minus"
                                        onClick={() => {
                                            trackEvent(AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE_PLUS);
                                            handleMinusButtonClick();
                                        }}
                                    >
                                        <img src={CircleButton} alt="Minus Button" />
                                    </button>
                                )}
                                <p> {rangeReserve} mi</p>
                                {rangeReserve < currentRange && (
                                    <button
                                        className="range-reserve-plus"
                                        onClick={() => {
                                            trackEvent(AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE_MINUS);
                                            handlePlusButtonClick();
                                        }}
                                    >
                                        <img src={CircleButton} alt="Plus Button" />
                                    </button>
                                )}
                            </div>
                            <div className="edit-range-reserve-slider">
                                <p className="edit-range-reserve-slider-start-label">10 mi</p>
                                <Form.Range
                                    value={rangeReserve}
                                    min={10}
                                    max={currentRange}
                                    onChange={handleSliderChange}
                                />
                                <p className="edit-range-reserve-slider-end-label">
                                    {currentRange} mi
                                </p>
                            </div>
                        </div>
                        <div className="edit-mode-body">
                            <div className="edit-mode-toggle-container">
                                <span>Automatic Transfer</span>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        data-testid="edit-mode-toggle"
                                        checked={automaticTransfer}
                                        onChange={() => {
                                            trackEvent(
                                                AMPLITUDE_EVENTS.EDIT_RANGE_RESERVE_EDIT_MODE_TOGGLE,
                                                {
                                                    Timestamp: new Date().toISOString(),
                                                    toggle: !automaticTransfer
                                                }
                                            );
                                            setAutomaticTransfer(!automaticTransfer);
                                        }}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div className="edit-mode-text">
                                <p>
                                    Turn Automatic Transfer on or off to determine if your vehicle
                                    will transfer Backup Power automatically (as soon as as outage
                                    is detected) or manually (only when you say so)
                                </p>
                            </div>
                        </div>
                    </div>
                    <PrivacyLinks data />
                </Modal.Body>
            </Modal>
        </div>
    );
}
