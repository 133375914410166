import { Form, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import BackButton from '../../assets/icons/Back_Arrow_Left_icon.svg';
import { AMPLITUDE_EVENTS, trackEvent } from '../../util/Amplitude.js';
import EditIcon from '../../assets/icons/Edit_icon.svg';
import CircleButton from '../../assets/icons/Circle_icon.svg';
import PrivacyLinks from '../PrivacyLinks/PrivacyLinks.jsx';
import './EditTargetCharge.scss';
import { useRecoilState, useRecoilValue } from 'recoil';
import { chargeSettingsAtom, showSideMenuAtom } from '../../atoms/vehicleState.js';
import PCTNotSet from '../PctNotSetErrorPage/PCTNotSet.jsx';

export default function EditTargetCharge({ updateTargetCharge, isHomePageFlow }) {
    const [selectedTsoc, setSelectedTsoc] = useState('');
    const [showEditTsoc, setShowEditTsoc] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const chargeSettings = useRecoilValue(chargeSettingsAtom);
    const [, setShowSideMenu] = useRecoilState(showSideMenuAtom);
    const tsocValues = [50, 60, 70, 80, 85, 90, 95, 100];

    useEffect(() => {
        if (chargeSettings && chargeSettings.target && tsocValues.includes(chargeSettings.target)) {
            setSelectedIndex(tsocValues.indexOf(chargeSettings.target));
            setSelectedTsoc(chargeSettings.target);
        } else {
            let nearestValue = getNearestValue(chargeSettings.target);
            setSelectedIndex(tsocValues.indexOf(nearestValue));
            setSelectedTsoc(nearestValue);
        }
    }, [showEditTsoc]);

    function getNearestValue(target) {
        return tsocValues.reduce((prev, curr) =>
            Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev
        );
    }

    const handlePlusButtonClick = () => {
        const newIndex = Math.min(Number(selectedIndex) + 1, 7);
        setSelectedIndex(newIndex);
        setSelectedTsoc(tsocValues[newIndex]);
    };

    const handleMinusButtonClick = () => {
        const newIndex = Math.max(Number(selectedIndex) - 1, 0);
        setSelectedIndex(newIndex);
        setSelectedTsoc(tsocValues[newIndex]);
    };

    const handleSliderChange = (e) => {
        trackEvent(AMPLITUDE_EVENTS.EDIT_TARGET_CHARGE_SLIDER);
        setSelectedIndex(e.target.value);
        setSelectedTsoc(tsocValues[e.target.value]);
    };

    const handleBackButtonClick = () => {
        trackEvent(AMPLITUDE_EVENTS.EDIT_TARGET_CHARGE_BACK);
        if (Number(selectedTsoc) !== chargeSettings.target) {
            updateTargetCharge(selectedTsoc);
            setShowSideMenu(false);
        }
        setShowEditTsoc(false);
    };

    return (
        <>
            {isHomePageFlow ? (
                <button
                    className="edit-button"
                    onClick={() => {
                        trackEvent(AMPLITUDE_EVENTS.EDIT_TARGET_CHARGE);
                        setShowEditTsoc(true);
                    }}
                >
                    <img src={EditIcon} alt="Edit Target Charge" />
                </button>
            ) : (
                <button
                    className="edit-button-side-menu"
                    onClick={() => {
                        trackEvent(AMPLITUDE_EVENTS.EDIT_TARGET_CHARGE_SIDE_MENU);
                        setShowEditTsoc(true);
                    }}
                >
                    Edit Target Charge
                </button>
            )}

            <Modal
                show={showEditTsoc}
                animation={true}
                centered
                className="edit-target-charge-modal"
            >
                <Modal.Body>
                    {chargeSettings.locationName === 'Unknown' ? (
                        <PCTNotSet
                            data-testid="pct-not-set"
                            showCloseButton={true}
                            closeButton={() => {
                                setShowEditTsoc(false);
                                setShowSideMenu(false);
                            }}
                        />
                    ) : (
                        <div className="edit-target-charge">
                            <div className="edit-target-charge-header">
                                <button
                                    className="arrow-button-back"
                                    onClick={handleBackButtonClick}
                                >
                                    <img src={BackButton} alt="Back Button" />
                                </button>
                                <p className="edit-target-charge-header-title">Target Charge</p>
                                <div className="edit-target-charge-header-text">
                                    <p>
                                        Target Charge Level can be adjusted during a charge session
                                        for your Home location.
                                    </p>
                                </div>
                            </div>
                            <div className="edit-target-charge-content">
                                <div className="edit-target-charge-selector">
                                    <button
                                        className="target-charge-minus"
                                        style={{
                                            visibility: selectedTsoc <= 50 ? 'hidden' : 'visible'
                                        }}
                                        onClick={() => {
                                            trackEvent(AMPLITUDE_EVENTS.EDIT_TARGET_CHARGE_MINUS);
                                            handleMinusButtonClick();
                                        }}
                                        aria-label="minus"
                                    >
                                        <img src={CircleButton} alt="Minus Button" />
                                    </button>
                                    <p data-testid="selected-tsoc"> {selectedTsoc}% </p>

                                    <button
                                        className="target-charge-plus"
                                        style={{
                                            visibility: selectedTsoc >= 100 ? 'hidden' : 'visible'
                                        }}
                                        onClick={() => {
                                            trackEvent(AMPLITUDE_EVENTS.EDIT_TARGET_CHARGE_PLUS);
                                            handlePlusButtonClick();
                                        }}
                                        aria-label="plus"
                                    >
                                        <img src={CircleButton} alt="Plus Button" />
                                    </button>
                                </div>
                                <div className="edit-target-charge-slider">
                                    <p className="edit-target-charge-slider-start-label">50%</p>
                                    <Form.Range
                                        min="0"
                                        max={tsocValues.length - 1}
                                        step="1"
                                        value={selectedIndex}
                                        onChange={handleSliderChange}
                                        style={{ '--value': `${(selectedIndex / 8) * 100}%` }}
                                    />
                                    <p className="edit-target-charge-slider-end-label">100%</p>
                                </div>
                                <div className="edit-target-charge-location-text">
                                    <p>
                                        {chargeSettings.locationName} -{' '}
                                        {chargeSettings.locationAddress}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    <PrivacyLinks />
                </Modal.Body>
            </Modal>
        </>
    );
}
