import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import './ChargeSettings.scss';
import config from '../../env/environment.js';
import EditIcon from '../../assets/icons/Edit_icon.svg';
import PopupModal from '../PopupModal/PopupModal';
import moment from 'moment';
import { Card, Stack } from 'react-bootstrap';
import { departureTimeAtom, chargeSettingsAtom, fetchLoadingAtom } from '../../atoms/vehicleState';
import { trackEvent, AMPLITUDE_EVENTS } from '../../util/Amplitude';
import EditTargetCharge from '../EditTargetCharge/EditTargetCharge.jsx';
import { customerStateAtom } from '../../atoms/customerState.js';
import UserType from '../../constants/UserTypeConst.js';

export default function ChargeSettings({ isSideMenu }) {
    const VIN = sessionStorage.getItem('VIN');

    const loading = useRecoilValue(fetchLoadingAtom);
    const customerState = useRecoilValue(customerStateAtom);
    const [departureTime, setDepartureTime] = useRecoilState(departureTimeAtom);
    const [chargeSettings, setChargeSettings] = useRecoilState(chargeSettingsAtom);

    const [showEditDialog, setShowEditDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState({ title: '', message: '' });
    const [editTsocInProgress, setEditTsocInProgress] = useState(false);
    const [newTsoc, setNewTsoc] = useState('');

    const getTargetCharge = () => {
        fetch(config.VEHICLE_STATUS_API_URL + `/energy-app/v1/target-charge/${VIN}`, {
            method: 'GET',
            mode: 'cors',
            headers: { 'Auth-Token': sessionStorage.getItem('catToken') }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.target) {
                    setChargeSettings(data);
                }
            })
            .catch((error) => {
                console.log('Error getting target charge settings.', error);
            });
    };

    const editTargetCharge = (newTsoc) => {
        setEditTsocInProgress(true);
        setNewTsoc(newTsoc);
        fetch(config.VEHICLE_STATUS_API_URL + `/energy-app/v1/target-charge/${VIN}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Auth-Token': sessionStorage.getItem('catToken'),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ targetSoc: newTsoc })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json().then((data) => {
                        if (data.status === 'SUCCESS') {
                            setEditTsocInProgress(false);
                            getTargetCharge();
                            return data;
                        } else {
                            throw new Error('Target charge update was not successful.');
                        }
                    });
                } else {
                    throw new Error(response);
                }
            })
            .catch((error) => {
                setEditTsocInProgress(false);
                showOrHideDialog(
                    true,
                    'Target Charge Error',
                    'Target charge update was not successful. Please try again.'
                );
            });
    };

    const getDepartureTime = () => {
        fetch(config.VEHICLE_STATUS_API_URL + `/energy-app/v1/departure-time/${VIN}`, {
            method: 'GET',
            mode: 'cors',
            headers: { 'Auth-Token': sessionStorage.getItem('catToken') }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.time) {
                    if (data.time !== 'Not Set') {
                        const timeString = moment(data.time, 'HH:mm').format('h:mm A');
                        setDepartureTime({
                            ...data,
                            timeStamp: timeString,
                            utcTime: getTimeInUTC(data.weekDay, data.time)
                        });
                    } else {
                        setDepartureTime(data);
                        console.log('Departure time not set for this vehicle');
                    }
                }
            })
            .catch((error) => {
                console.log('Error getting departure time.', error);
            });
    };

    const showOrHideDialog = (showDialog, dialogTitle = '', dialogMessage = '') => {
        setShowEditDialog(showDialog);
        setDialogMessage({ title: dialogTitle, message: dialogMessage });
    };

    const getTimeInUTC = (weekDay, time) => {
        const parsedTime = moment(time, 'HH:mm');
        const hours = parsedTime.hours();
        const minutes = parsedTime.minutes();
        let date = moment.utc();
        // Get the index of the current weekday and the given weekday
        const nowWeekdayIndex = date.day();
        const weekdayIndex = moment().day(weekDay).day();

        // Calculate the number of days until the next occurrence of the given weekday
        const daysUntilNextWeekday =
            weekdayIndex > nowWeekdayIndex
                ? weekdayIndex - nowWeekdayIndex
                : 7 - nowWeekdayIndex + weekdayIndex;

        date = date.add(daysUntilNextWeekday, 'days');
        const offset = moment().utcOffset() / 60;
        date = date.hours(hours - offset).minutes(minutes);
        const utcString = date.toISOString();
        return utcString;
    };

    useEffect(() => {
        getTargetCharge();
        getDepartureTime();
        const interval = setInterval(() => {
            getTargetCharge();
            getDepartureTime();
        }, 60000); // calls every minutes
        return () => clearInterval(interval);
    }, []);

    if (!loading) {
        if (isSideMenu) {
            return (
                <EditTargetCharge updateTargetCharge={editTargetCharge} isHomePageFlow={false} />
            );
        } else {
            return (
                <div className="charge-settings">
                    <div className="energy-schedule-header">Energy Schedule</div>
                    <div className="charge-settings-cards">
                        <PopupModal
                            className="charge-settings-modal"
                            id="edit-dialog"
                            displayDialog={showEditDialog}
                            closeDialog={() => showOrHideDialog(false)}
                            dialogTextList={[dialogMessage.message]}
                            dialogTitle={dialogMessage.title}
                        />
                        <Stack direction="horizontal" gap={3}>
                            <Card
                                className="rounded-card col-card"
                                style={{ opacity: editTsocInProgress ? '0.5' : '1' }}
                            >
                                <Stack className="p-1" gap={0}>
                                    <div className="text-row-1">
                                        {chargeSettings.panelTitle}
                                        {customerState.userType ===
                                            UserType.HOME_POWER_MANAGEMENT &&
                                            !editTsocInProgress && (
                                                <EditTargetCharge
                                                    updateTargetCharge={editTargetCharge}
                                                    isHomePageFlow={true}
                                                />
                                            )}
                                    </div>

                                    <Stack direction="horizontal" gap={2}>
                                        <div className="text-row-2">
                                            {editTsocInProgress ? newTsoc : chargeSettings.target}
                                            <span className="text-sm">%</span>
                                        </div>
                                    </Stack>

                                    <Stack direction="horizontal" gap={2}>
                                        <div className="text-row-3">
                                            {chargeSettings.targetLabel}
                                        </div>
                                    </Stack>
                                </Stack>
                            </Card>
                            <Card className="rounded-card col-card">
                                <Stack className="p-1">
                                    <div className="text-row-1">Departure Time</div>
                                    {departureTime.time !== 'Not Set' ? (
                                        <>
                                            <div className="text-row-2">
                                                {departureTime.timeStamp.split(' ')[0]}
                                                <span className="text-sm">
                                                    {departureTime.timeStamp.split(' ')[1]}
                                                </span>
                                            </div>
                                            <div className="text-row-3">
                                                {departureTime.weekDay.substring(0, 3)}
                                                {departureTime.temp !== '' &&
                                                    ', ' + departureTime.temp}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="text-row-2">
                                                <span className="text-xl">Not Set</span>
                                            </div>
                                            <div className="text-row-3">---</div>
                                        </>
                                    )}
                                </Stack>
                            </Card>
                        </Stack>
                    </div>
                </div>
            );
        }
    }
}
