import React, { useEffect, useState } from 'react';
import './BackupPowerTile.scss';
import DischargeModal from '../DischargeModal/DischargeModal.jsx';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Card, Modal, ModalHeader, Stack } from 'react-bootstrap';
import { fetchLoadingAtom, vehicleStateAtom } from '../../atoms/vehicleState';
import userTypeConst from '../../constants/UserTypeConst.js';
import ChargeModal from '../ChargeModal/ChargeModal.jsx';
import EditRangeReserve from '../EditRangeReserve/EditRangeReserve.jsx';

export default function BackupPowerTile({ userType }) {
    const loading = useRecoilValue(fetchLoadingAtom);
    const vehicleState = useRecoilValue(vehicleStateAtom);

    const showBackupPowerTile = () => {
        return (
            vehicleState.reserve !== undefined &&
            vehicleState.reserve !== null &&
            vehicleState.reserve !== 0
        );
    };

    if (!loading && showBackupPowerTile()) {
        return (
            <div className="backup-power-tile">
                <Card className="rounded-card col-card">
                    <Stack direction="horizontal" className="p-1" gap={3}>
                        <Stack direction="vertical" className="backup-power-section">
                            <div className="backup-power-tile-header">Home Backup Power</div>
                            <div className="text-row-2 w-45">{vehicleState.reserve} mi</div>
                            <div className="text-row-3 w-45">range reserve</div>
                        </Stack>
                        <Stack direction="vertical" className="backup-power-section">
                            <EditRangeReserve isHomePageFlow={true} />
                            {vehicleState.chargeModal.buttonVisible &&
                                userType === userTypeConst.HOME_BACKUP_POWER && (
                                    <ChargeModal
                                        buttonLabel={vehicleState.chargeModal.buttonLabel}
                                        buttonDisabled={vehicleState.chargeModal.buttonDisabled}
                                        userType={userType}
                                        scheduleCalculated={true} //Backup Power button doesn't have a schedule, chargeModal will disable schedule if false though and we don't want that to happen
                                        data-testid="chargemodal-component"
                                    />
                                )}
                            {vehicleState.dischargeModal.buttonVisible && (
                                <DischargeModal
                                    buttonLabel={vehicleState.dischargeModal.buttonLabel}
                                    buttonDisabled={vehicleState.dischargeModal.buttonDisabled}
                                    powerOutage={vehicleState.powerOutage}
                                    data-testid="dischargemodal-component"
                                />
                            )}
                        </Stack>
                    </Stack>
                </Card>
            </div>
        );
    }
}
