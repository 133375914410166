import React from 'react';
import { Button, Stack } from 'react-bootstrap';
import NotAuthorizedImage from '../../assets/icons/Not_authorized.svg';
import PrivacyLinks from '../../components/PrivacyLinks/PrivacyLinks.jsx';
import { AMPLITUDE_EVENTS, trackEvent } from '../../util/Amplitude';
import PlusButton from '../../assets/icons/Plus_icon.svg';
import './PCTNotSet.scss';

export default function PCTNotSet({ showCloseButton, closeButton }) {
    trackEvent(AMPLITUDE_EVENTS.IP_PCT_NOT_SET);

    return (
        <div className={`pct-not-set ${showCloseButton ? 'showClose' : ''}`}>
            {showCloseButton && (
                <button className="close-button" onClick={closeButton}>
                    <img src={PlusButton} alt="Close Button" />
                </button>
            )}
            <Stack gap={3} className="onboarding-content background-gray m-auto">
                <img src={NotAuthorizedImage} className="icon-image" alt="Not Authorized Icon" />
                <p className="status-text">
                    To use Ford Home Power Management, please set a Preferred Charge Time in the
                    FordPass app or in your vehicle.
                </p>
                <ol className="ordered-list -indent-[20px] px-12">
                    <li>
                        1. Open your <b>FordPass</b> app (linked to your EV) on your mobile phone
                    </li>
                    <li>
                        2. Select <b>Energy</b> from the bottom tabs
                    </li>
                    <li>
                        3. Select <b>Preferred Charge Times</b> under the header <b>Schedule</b>
                    </li>
                </ol>
                <div className="contact-link">
                    Having trouble? <a href="mailto:myenergy@ford.com">Contact Us.</a>
                </div>
                <PrivacyLinks data-test-id="privacy-links" />
            </Stack>
        </div>
    );
}
